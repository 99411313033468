import { Flex, TextInput } from '@applyboard/crystal-ui'
import { useDebouncedState, useProgramFilterSearchParams } from '../../../hooks'

import { ProgramFiltersDialog } from './ProgramFilterDialog'
import { ProgramFiltersForm } from './ProgramFiltersForm'
import styled from '@emotion/styled'
import { FilterSummary } from './FilterSummary'

export function ProgramFilters() {
  
  const { filters, updateFilters } = useProgramFilterSearchParams()
  const [currentValue, _, setSearchInput] = useDebouncedState(filters.q, {
    delay: 500,
    onChange: () => {
      updateFilters({ ...filters, q: currentValue })
    }
  });

  return (
    <Flex direction="column" gap={{ xs: 6, md: 0 }}>
      <Flex gap={3} justify="between">
        <Flex basis='100%'>
          <FormWrapperStyled>
            <TextInput
              aria-label="Program Name"
              intent="secondary"
              onChange={setSearchInput}
              placeholder="Program Name"
              size="md"
              type="search"
              value={currentValue}
            />
          </FormWrapperStyled>
        </Flex>
        <Flex hideBelow='md'>
          <ProgramFiltersForm isDesktop={true} />
        </Flex>
        <Flex hideAbove='md'>
          <ProgramFiltersDialog />
        </Flex>
      </Flex>
      <FilterSummary />
    </Flex>
  )
}

const FormWrapperStyled = styled.div(`
  width: 100%;
  > form { width: 100%; }
`);