import { useCallback, useEffect, useMemo, useState } from 'react'
import { nanoid } from 'nanoid'
import {
  createDatePickerValidator,
  createForm,
  DatePicker,
  Flex,
  NumberInput,
  Select,
  Text,
  TextInput,
} from '@applyboard/crystal-ui'
import { find, isEmpty, set } from 'lodash'
import {
  FileData,
  HasLanguageProficiencyCodes,
  LanguageProficiency,
  LanguageProficiencyData,
  TestType,
} from 'applications-types-lib'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import { Loading } from '../../Loading'

import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'

import { ApplicationFormCard } from './ApplicationFormCard'
import { useApplicationFormContext } from './ApplicationForm'
import { FileUploadField, FileUploadFieldValue } from './FileUploadField'
import { ChangeLanguageTestDialog } from '../ChangeLanguageTestDialog'

import { DocumentTags, LanguageExamAvailability } from '../../../utils/enums'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import {
  getLanguageProficiencyFieldsInfo,
  getSubscoresOrder,
  Subscores,
  validateSubscore,
} from '../../../utils'
import { getFilesOfType } from './utils'
import { StudentApplication } from '../types'
import { Asterisk } from '../../Asterisk'

type LanguageProficiencyFormFields = {
  hasLanguageProficiency: string
  id: string
  testType: string
  certificateNumber: string
  testDate: string
  overallScore: string
  documents: FileUploadFieldValue[]
  listening: string
  reading: string
  writing: string
  speaking: string
  description: string
}

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<LanguageProficiencyFormFields>()

type LanguageProficiencyTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
  currentIntakeAvailable?: boolean
  onIntakeUnavailable?: () => void
  formId: string
}

const UPLOAD_LIMIT = 1

const defaultValue = {
  testType: '',
  certificateNumber: '',
  testDate: '',
  overallScore: '',
  documents: [],
  listening: '',
  reading: '',
  writing: '',
  speaking: '',
  description: '',
}

export function LanguageProficiencyTab(props: Readonly<LanguageProficiencyTabProps>) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()

  const languageProficiencyFiles = useMemo(() => 
    getLanguageProficiencyFiles(props.application?.attributes?.files as FileData),
    [props.application?.attributes?.files]
  )

  useEffect(() => {
    const activeFiles = Object.entries(languageProficiencyFiles).reduce((acc, [id, file]) => {
      if (file?.activeRecord) {
        acc[id] = file
      }

      return acc
    }, {} as FileData)

    resetFiles(activeFiles)
  }, [languageProficiencyFiles, resetFiles])

  const handleSubmit = useCallback((data: LanguageProficiencyFormFields) => {
    if (props.disabled) {
      props.onSuccess()
    } else if (props.onIntakeUnavailable && !props.currentIntakeAvailable) {
      props.onIntakeUnavailable()
    } else {
      const languageProficiency = {
        hasLanguageProficiency: data.hasLanguageProficiency as HasLanguageProficiencyCodes,
        languageProficiencyData: {} as LanguageProficiencyData,
      }

      let filesToRemove: Record<string, null> = {}

      if (data.hasLanguageProficiency !== LanguageExamAvailability.HAVE) {
        set(languageProficiency, 'languageProficiencyData', null)
        filesToRemove = Object.keys(languageProficiencyFiles).reduce((acc, fileId) => {
          acc[fileId] = null
          return acc
        }, {} as Record<string, null>)
      } else if ((data.testType as TestType) === 'OTHER') {
        languageProficiency.languageProficiencyData[data.id] = {
          testType: data.testType as TestType,
          description: data.description,
        }
      } else if (getLanguageProficiencyFieldsInfo(data.testType)) {
        const examData = {
          testType: data.testType as TestType,
          certificateNumber: data.certificateNumber,
          testDate: data.testDate.substring(0, 10),
          overallScore: data.overallScore,
          subscores: {
            listening: parseFloat(data.listening),
            reading: parseFloat(data.reading),
            writing: parseFloat(data.writing),
            speaking: parseFloat(data.speaking),
          },
        }
        languageProficiency.languageProficiencyData[data.id] = examData
      }

      updateApplication(
        {
          attributes: {
            languageProficiency,
          },
          files: {
            ...pendingFileUploadState,
            // Removes previously saved documents when the user changes the status from HAVE to either NO_NEED or WILL_HAVE
            ...filesToRemove, 
          },
        },
        {
          onSuccess: response => {
            resetFiles(
              getLanguageProficiencyFiles(response.data?.attributes?.files as FileData)
            )
            props.onSuccess(response)
          },
          onError: props.onError,
        },
      )
    }
  }, [props, updateApplication, pendingFileUploadState, languageProficiencyFiles, resetFiles])

  return (
    <Flex grow={1} direction="column">
      <Form
        id={props.formId}
        defaultValues={setDefaultLanguageProficiency({
          languageProficiency: props.application?.attributes?.languageProficiency,
          files: languageProficiencyFiles,
        })}
        validationMode={'onSubmit'}
        onSubmit={handleSubmit}
      >
        <ApplicationFormCard
          cardNumber={5}
          icon="🗣️"
          title="Language Proficiency"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <LanguageProficiencyItems disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type LanguageProficiencyItemsProps = {
  disabled?: boolean
  application: StudentApplication
}

function LanguageProficiencyItems(props: Readonly<LanguageProficiencyItemsProps>) {
  const { addPendingDelete, getObservableFiles, resetFiles } = useApplicationFormContext()

  const [dialogData, setDialogData] = useState<{
    currentTestValue: string
    isOpen: boolean
    newTestValue: string | null
  }>({
    currentTestValue: '',
    isOpen: false, // is the dialog open or closed
    newTestValue: null,
  })

  const {
    certificateNumber,
    documents,
    hasLanguageProficiency,
    id,
    listening,
    overallScore,
    reading,
    speaking,
    testDate,
    testType,
    writing,
    description,
  } = useFieldValues([
    'certificateNumber',
    'documents',
    'hasLanguageProficiency',
    'id',
    'listening',
    'overallScore',
    'reading',
    'speaking',
    'testDate',
    'testType',
    'writing',
    'description',
  ])
  const setFieldValues = useSetFieldValues()

  const testTypeOptions = {
    IELTS: 'IELTS',
    PTE: 'PTE Academic',
    TOEFL: 'TOEFL®️',
    OTHER: 'Other',
  }

  if (!id) {
    return <Loading />
  }

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text>
          Please provide information about the student's language proficiency as per the program
          requirements.
        </Text>
        <Flex direction="column" gap={4}>
          <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
            <Flex.Item basis="100%">
              <Field
                as={Select}
                label="Will you provide language proficiency proof now?"
                name={`hasLanguageProficiency`}
                appearance="styled"
                disabled={props.disabled}
                required="This field is required"
                onChange={value => {
                  // this works due to us only supporting 1 test in this form
                  resetFiles(
                    getFilesOfType(
                      [
                        DocumentTags.LANGUAGE_TEST_IELTS,
                        DocumentTags.LANGUAGE_TEST_TOEFL,
                        DocumentTags.LANGUAGE_TEST_PTE,
                        DocumentTags.LANGUAGE_TEST_OTHER,
                      ],
                      props.application?.attributes?.files as FileData,
                    ),
                  )

                  setFieldValues({
                    hasLanguageProficiency: value as string,
                    id: id,
                    ...defaultValue,
                  })
                }}
              >
                <Select.Option value="HAVE" label="Yes, and I can provide this now" />
                <Select.Option value="WILL_HAVE" label="Yes, but I will provide this later" />
                <Select.Option value="DONT_NEED" label="No, I don’t need to provide this" />
              </Field>
            </Flex.Item>
            {hasLanguageProficiency === LanguageExamAvailability.HAVE ? (
              <>
                <Flex.Item basis="100%">
                  <Field
                    as={Select}
                    label="Proof type"
                    name={`testType`}
                    appearance="styled"
                    disabled={props.disabled}
                    required={
                      isFieldRequired({
                        testType,
                        hasLanguageProficiency: hasLanguageProficiency,
                        disabled: props.disabled,
                      })
                        ? 'Proof type is required'
                        : false
                    }
                    onChange={value => {
                      if (
                        testType.length !== 0 &&
                        (!isEmpty(testDate) ||
                          !isEmpty(certificateNumber) ||
                          !isEmpty(documents) ||
                          !isEmpty(
                            getObservableFiles({ fileType: testType, sectionReference: id }),
                          ) ||
                          !isEmpty(overallScore) ||
                          !isEmpty(listening) ||
                          !isEmpty(reading) ||
                          !isEmpty(speaking) ||
                          !isEmpty(writing) ||
                          !isEmpty(description))
                      ) {
                        setDialogData({
                          currentTestValue: testType,
                          isOpen: true,
                          newTestValue: (value as string | undefined) || null,
                        })
                      }
                    }}
                  >
                    {Object.entries(testTypeOptions).map(([value, label]) => (
                      <Select.Option value={value} label={label} key={value} />
                    ))}
                  </Field>
                </Flex.Item>
                <SelectedTypeFields
                  disabled={props.disabled}
                  application={props.application}
                />
                <SelectedOtherTypeFields disabled={props.disabled} />
                <UploadField disabled={props.disabled} application={props.application} />
              </>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <ChangeLanguageTestDialog
        currentTestLabel={
          testTypeOptions[dialogData.currentTestValue as keyof typeof testTypeOptions]
        }
        isOpen={dialogData.isOpen}
        newTestLabel={
          dialogData.newTestValue
            ? testTypeOptions[dialogData.newTestValue as keyof typeof testTypeOptions]
            : ''
        }
        onCloseDialog={(isOpen: boolean) => {
          setFieldValues({
            testType: dialogData.currentTestValue,
          })

          setDialogData({
            currentTestValue: '',
            isOpen: isOpen,
            newTestValue: null,
          })
        }}
        onConfirm={() => {
          const fieldsInfo = getLanguageProficiencyFieldsInfo(dialogData.currentTestValue)
          Object.keys(
            getObservableFiles({ fileType: fieldsInfo?.fileType, sectionReference: id }),
          ).forEach(id => {
            addPendingDelete(id)
          })

          setFieldValues({
            ...defaultValue,
            testType: dialogData.newTestValue || '',
          })

          setDialogData({
            currentTestValue: '',
            isOpen: false,
            newTestValue: null,
          })
        }}
      />
    </>
  )
}

type SelectedOtherTypeFieldsProps = {
  readonly disabled?: boolean
}

function SelectedOtherTypeFields(props: SelectedOtherTypeFieldsProps) {
  const { testType } = useFieldValues(['testType'])
  if (testType !== 'OTHER') {
    return null
  }
  return (
    <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex.Item basis={{ xs: '100%' }}>
        <Field
          as={TextInput}
          label="Please specify other proof type"
          name={`description`}
          disabled={props.disabled}
          helpText="Provide a description with up to 200 characters"
          validate={value => {
            if (value.length > 200) {
              return 'This field has a character limit of 200'
            }
            return true
          }}
          required={'This field is required'}
        />
      </Flex.Item>
    </Flex>
  )
}

type SelectedTypeFieldsProps = {
  disabled?: boolean
  application: StudentApplication
}

function SelectedTypeFields(props: SelectedTypeFieldsProps) {
  const { hasLanguageProficiency, testType } = useFieldValues([
    'hasLanguageProficiency',
    'testType',
  ])

  if (testType === 'OTHER') {
    return null
  }

  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  if (!fieldsInfo) {
    return null
  }

  const subscoreOrder = getSubscoresOrder(testType)

  const maxDate = new Date()
  const minDate = new Date()

  minDate.setFullYear(minDate.getFullYear() - 50)

  const datePickerValidator = createDatePickerValidator(minDate, maxDate, 'day')

  return (
    <>
      <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={DatePicker}
            label="Test date"
            name={`testDate`}
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            validate={datePickerValidator}
            disabled={props.disabled}
            required={
              isFieldRequired({
                testType,
                disabled: props.disabled,
                hasLanguageProficiency: hasLanguageProficiency,
              })
                ? 'Test date is required'
                : false
            }
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput}
            label={fieldsInfo.codeLabel}
            name={`certificateNumber`}
            disabled={props.disabled}
            required={
              isFieldRequired({
                testType,
                disabled: props.disabled,
                hasLanguageProficiency: hasLanguageProficiency,
              })
                ? `${fieldsInfo.codeLabel} is required`
                : false
            }
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={NumberInput}
            label="Overall score"
            name={`overallScore`}
            max={fieldsInfo.maxOverall}
            min={fieldsInfo.minOverall}
            required={
              isFieldRequired({
                testType,
                disabled: props.disabled,
                hasLanguageProficiency: hasLanguageProficiency,
              })
                ? 'Overall score is required'
                : false
            }
            validate={data => {
              if (
                parseInt(data) < (fieldsInfo.minOverall || 0) ||
                parseInt(data) > (fieldsInfo.maxOverall || 0)
              ) {
                return 'Invalid score'
              }

              return true
            }}
            disabled={props.disabled}
          />
        </Flex.Item>
      </Flex>
      <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        {subscoreOrder.map(subscore => (
          <SubscoresField
            key={`${subscore.name}`}
            label={subscore.label}
            name={subscore.name}
            disabled={props.disabled}
          />
        ))}
      </Flex>
    </>
  )
}

type UploadFieldProps = {
  readonly disabled?: boolean
  readonly application: StudentApplication
}

function UploadField(props: UploadFieldProps) {
  const { id, testType, documents } = useFieldValues(['id', 'testType', 'documents'])
  const setFieldValues = useSetFieldValues()
  const { getObservableFiles } = useApplicationFormContext()
  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  if (!fieldsInfo) {
    return null
  }
  return (
    <Flex basis="100%" direction="column" gap={4}>
      <Field
        as={FileUploadField}
        allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
        application={props.application}
        disabled={props.disabled}
        fileLimit={UPLOAD_LIMIT}
        fileType={fieldsInfo.fileType}
        label={
          <Text variant={'bodyM'}>
            Add the language proficiency document below, supported file formats: JPG, JPEG, PDF,
            PNG, max number of files: {UPLOAD_LIMIT} <Asterisk />
          </Text>
        }
        name={`documents`}
        onRemove={(id: string) =>
          setFieldValues({ documents: documents.filter(file => file.id !== id) })
        }
        section={id}
        showHistory={!!props.disabled}
        validate={value => {
          const observableFiles = getObservableFiles({
            fileType: fieldsInfo.fileType,
            sectionReference: id,
          })
          if (Object.keys(observableFiles).length > UPLOAD_LIMIT) {
            return `This field has a file limit of ${UPLOAD_LIMIT}.`
          }
          if (!(Object.keys(observableFiles).length || value.length)) {
            return 'This field is required'
          }
          return true
        }}
      />
    </Flex>
  )
}

type SubscoresFieldProps = {
  label: string
  name: Subscores
  disabled?: boolean
}

function SubscoresField(props: Readonly<SubscoresFieldProps>) {
  const { hasLanguageProficiency, testType } = useFieldValues([
    'hasLanguageProficiency',
    'testType',
  ])
  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  if (!fieldsInfo) return null

  return (
    <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)', md: 'calc(25% - 12px)' }}>
      <Field
        as={NumberInput}
        label={props.label}
        name={`${props.name}`}
        max={fieldsInfo.maxSubscore}
        min={fieldsInfo.minSubscore}
        step={fieldsInfo.step}
        required={
          isFieldRequired({
            testType,
            disabled: props.disabled,
            hasLanguageProficiency: hasLanguageProficiency,
          })
            ? `${props.label} score is required`
            : false
        }
        validate={data => {
          return validateSubscore(testType, parseInt(data))
        }}
        disabled={props.disabled}
      />
    </Flex.Item>
  )
}

function setDefaultLanguageProficiency({
  languageProficiency,
  files,
}: {
  languageProficiency?: SetAllFieldsOptional<LanguageProficiency>
  files: FileData
}) {
  let formData = {
    hasLanguageProficiency: languageProficiency?.hasLanguageProficiency || '',
    ...defaultValue,
    id: nanoid(),
  }
  if (
    !languageProficiency?.languageProficiencyData ||
    !Object.keys(languageProficiency.languageProficiencyData).length
  )
    return formData

  const activeDocument = find(
    files,
    doc =>
      ((doc?.type as unknown as DocumentTags | undefined) === DocumentTags.LANGUAGE_TEST_IELTS ||
        (doc?.type as unknown as DocumentTags | undefined) === DocumentTags.LANGUAGE_TEST_PTE ||
        (doc?.type as unknown as DocumentTags | undefined) === DocumentTags.LANGUAGE_TEST_OTHER ||
        (doc?.type as unknown as DocumentTags | undefined) === DocumentTags.LANGUAGE_TEST_TOEFL) &&
      !!doc?.activeRecord,
  )

  const id = activeDocument?.sectionReference || ''

  const exam = languageProficiency.languageProficiencyData[id]
  formData = {
    hasLanguageProficiency: languageProficiency?.hasLanguageProficiency || '',
    testType: exam?.testType || '',
    certificateNumber: exam?.certificateNumber || '',
    documents: [],
    testDate: exam?.testDate ? convertTimelessDateStrToLocalDate(exam.testDate).toISOString() : '',
    overallScore: exam?.overallScore || '',
    listening: exam?.subscores?.listening?.toString() || '',
    reading: exam?.subscores?.reading?.toString() || '',
    writing: exam?.subscores?.writing?.toString() || '',
    speaking: exam?.subscores?.speaking?.toString() || '',
    description: exam?.description || '',
    id: id,
  }

  return formData
}

function isFieldRequired(params: {
  testType: string
  hasLanguageProficiency?: string
  disabled?: boolean
}) {
  if (params.disabled || params.hasLanguageProficiency !== LanguageExamAvailability.HAVE) {
    return false
  }

  return true
}

const getLanguageProficiencyFiles = (files: FileData) => {
  return getFilesOfType(
    [
      DocumentTags.LANGUAGE_TEST_IELTS,
      DocumentTags.LANGUAGE_TEST_TOEFL,
      DocumentTags.LANGUAGE_TEST_PTE,
      DocumentTags.LANGUAGE_TEST_OTHER,
    ],
    files
  )
}