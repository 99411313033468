import {
  Button,
  Combobox,
  DatePicker,
  Divider,
  Flex,
  Select,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import { DeleteOutlineIcon, PlusOutlineIcon } from '@applyboard/ui-icons'
import {
  EducationHistory,
  FileData,
  EducationLevel as LevelOfEducation,
} from 'applications-types-lib'
import { isEmpty } from 'lodash'
import { nanoid } from 'nanoid'
import { useEffect } from 'react'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { DocumentTags, EducationLevel } from '../../../utils/enums'
import { Asterisk } from '../../Asterisk'
import { StudentApplication } from '../types'
import { useApplicationFormContext } from './ApplicationForm'
import { ApplicationFormCard } from './ApplicationFormCard'
import { FileUploadField } from './FileUploadField'
import { getFilesOfType } from './utils'

type EducationalAccomplishment = {
  id: string
  educationLevel: string
  country: string
  credentialReceived: string
  startDate: string
  endDate: string
  transcriptDocuments: Array<{
    id: string
    file: File
  }>
  certificateDocuments: Array<{
    id: string
    file: File
  }>
}

type EducationHistoryFormFields = {
  educationHistory: EducationalAccomplishment[]
}

const { Form, Field, useFieldArray, useFieldValues, useSetFieldValues } =
  createForm<EducationHistoryFormFields>()

type EducationHistoryTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
  currentIntakeAvailable?: boolean
  onIntakeUnavailable?: () => void
}

export function EducationHistoryTab(props: Readonly<EducationHistoryTabProps>) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()

  useEffect(() => {
    resetFiles(
      getFilesOfType(
        [DocumentTags.TRANSCRIPTS, DocumentTags.CERTIFICATE],
        props.application?.attributes?.files as FileData,
        { activeFiles: true },
      ),
    )
  }, [props.application?.attributes?.files, resetFiles])

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={setDefaultEducationHistory(props.application?.attributes?.educationHistory)}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else if (props.onIntakeUnavailable && !props.currentIntakeAvailable) {
            props.onIntakeUnavailable()
          } else {
            const edHist: SetAllFieldsOptional<EducationHistory> = {}

            data.educationHistory.forEach((formValues: EducationalAccomplishment) => {
              edHist[formValues.id] = {
                educationLevel:
                  (formValues.educationLevel && (formValues.educationLevel as LevelOfEducation)) ||
                  undefined,
                educationCountry:
                  (formValues.country && (formValues.country as CountryIsoCode)) || undefined,
                credentialReceived: formValues?.credentialReceived || '',
                startDate: formValues?.startDate?.substring(0, 7),
                endDate: formValues?.endDate?.substring(0, 7),
              }
            })

            if (props.application?.attributes?.educationHistory) {
              const initialEducationHistoryIds = Object.keys(
                props.application?.attributes?.educationHistory,
              )
              const edHistIdsToRemove = initialEducationHistoryIds.filter(id => {
                return !edHist[id]
              })
              if (edHistIdsToRemove.length) {
                edHistIdsToRemove.forEach(id => (edHist[id] = null))
              }
            }

            updateApplication(
              {
                attributes: {
                  educationHistory: edHist,
                },
                files: pendingFileUploadState,
              },
              {
                onSuccess: response => {
                  resetFiles(
                    getFilesOfType(
                      [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
                      response.data?.attributes?.files as FileData,
                    ),
                  )
                  props.onSuccess(response)
                },
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={4}
          icon="📚"
          title="Education History"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <EducationHistoryItems
            disabled={props.disabled}
            application={props.application}
          />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type EducationHistoryItemsProps = {
  disabled?: boolean
  application: StudentApplication
}

function EducationHistoryItems(props: Readonly<EducationHistoryItemsProps>) {
  const setFieldValues = useSetFieldValues()
  const { fields, append, remove } = useFieldArray({ name: 'educationHistory' })
  const { educationHistory } = useFieldValues(['educationHistory'])
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() + 15)
  minDate.setFullYear(minDate.getFullYear() - 50)

  return (
    <>
      <Flex direction="column" gap={4}>
        <Text variant="bodyM">
          Please provide the student's education history as per the program requirements.
        </Text>
        {fields.map((field, index) => {
          if (index >= educationHistory.length) return null

          return (
            <Flex direction="column" gap={4} key={field.id}>
              <Text variant="titleS">{`Education Experience ${index + 1}`}</Text>
              <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={Select}
                    label="Education level"
                    name={`educationHistory.${index}.educationLevel`}
                    appearance="styled"
                    disabled={props.disabled}
                    required={
                      isFieldRequired({ disabled: props.disabled })
                        ? 'Education level is required'
                        : false
                    }
                  >
                    <Select.Option
                      value={EducationLevel.PRIMARY_SCHOOL}
                      label="Elementary / Primary"
                    />
                    <Select.Option
                      value={EducationLevel.MIDDLE_SCHOOL}
                      label="Junior Secondary / Middle School"
                    />
                    <Select.Option
                      value={EducationLevel.HIGH_SCHOOL}
                      label="Senior Secondary / High School"
                    />
                    <Select.Option value={EducationLevel.UNDERGRAD} label="Undergraduate" />
                    <Select.Option value={EducationLevel.GRADUATE} label="Graduate" />
                    <Select.Option value={EducationLevel.PHD} label="Doctorate" />
                  </Field>
                </Flex.Item>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={Combobox}
                    label="Education country"
                    name={`educationHistory.${index}.country`}
                    size="md"
                    placeholder="Select"
                    disabled={props.disabled}
                    required={
                      isFieldRequired({ disabled: props.disabled })
                        ? 'Education country is required'
                        : false
                    }
                  >
                    {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                      <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                    ))}
                  </Field>
                </Flex.Item>
                <Flex.Item basis="100%">
                  <Field
                    as={TextInput}
                    label="Credential gained"
                    name={`educationHistory.${index}.credentialReceived`}
                    disabled={props.disabled}
                    helpText="E.g. Bachelors of Applied Science in Civil Engineering or High School Diploma"
                    required={
                      isFieldRequired({ disabled: props.disabled })
                        ? 'Credential gained is required'
                        : false
                    }
                  />
                </Flex.Item>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={DatePicker}
                    label="Education start"
                    name={`educationHistory.${index}.startDate`}
                    maxDate={maxDate.toISOString()}
                    minDate={minDate.toISOString()}
                    granularity={'month'}
                    disabled={props.disabled}
                    required={
                      isFieldRequired({ disabled: props.disabled })
                        ? 'Education start date is required'
                        : false
                    }
                    validate={date => {
                      const startDate = new Date(date)
                      const endDate = new Date(educationHistory[index].endDate)
                      if (endDate && startDate >= endDate) {
                        return 'Education start date must be before end date'
                      }

                      return true
                    }}
                  />
                </Flex.Item>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={DatePicker}
                    label="Education end (or expected)"
                    name={`educationHistory.${index}.endDate`}
                    maxDate={maxDate.toISOString()}
                    minDate={minDate.toISOString()}
                    granularity={'month'}
                    disabled={props.disabled}
                    required={
                      isFieldRequired({ disabled: props.disabled })
                        ? 'Education end date is required'
                        : false
                    }
                    validate={date => {
                      const endDate = new Date(date)
                      const startDate = new Date(educationHistory[index].startDate)
                      if (startDate && startDate >= endDate) {
                        return 'Education end date must be after start date'
                      }

                      return true
                    }}
                  />
                </Flex.Item>
                <Flex gap={4} direction="column" basis="100%">
                  <Text variant={'bodyM'}>
                    Add supporting document(s) below (at least one is required). Supported file
                    formats: PDF, JPEG, PNG <Asterisk />
                  </Text>
                  <Field
                    as={FileUploadField}
                    allowedFileTypes={['.jpg', '.jpeg', '.pdf', '.png']}
                    application={props.application}
                    disabled={props.disabled}
                    fileLimit={1}
                    fileType={DocumentTags.TRANSCRIPTS}
                    label={
                      <Flex direction={'column'} gap={1}>
                        <Text variant={'labelM'}>Document type</Text>
                        <Text variant={'bodyM'}>Transcript</Text>
                      </Flex>
                    }
                    name={`educationHistory.${index}.transcriptDocuments`}
                    onRemove={(id: string) => {
                      const newEducationHistory = [...educationHistory]
                      newEducationHistory[index].transcriptDocuments = newEducationHistory[
                        index
                      ].transcriptDocuments.filter(file => file.id !== id)
                      setFieldValues({ educationHistory: newEducationHistory })
                    }}
                    section={educationHistory[index].id}
                    showHistory={!!props.disabled}
                    validate={value => {
                      const observableFiles = getObservableFiles({
                        fileType: DocumentTags.TRANSCRIPTS,
                        sectionReference: educationHistory[index].id,
                      })

                      const transcriptHasValue =
                        !!value.length || !!Object.keys(observableFiles).length
                      const certificateHasValue =
                        !!educationHistory[index].certificateDocuments.length ||
                        !!Object.keys(
                          getObservableFiles({
                            fileType: DocumentTags.CERTIFICATE,
                            sectionReference: educationHistory[index].id,
                          }),
                        ).length

                      if (Object.keys(observableFiles).length > 1) {
                        return `This field has a file limit of ${1}.`
                      }

                      if (!(transcriptHasValue || certificateHasValue)) {
                        return 'At least one transcript or certificate must be attached.'
                      }
                      return true
                    }}
                  />
                  <Field
                    as={FileUploadField}
                    allowedFileTypes={['.jpg', '.jpeg', '.pdf', '.png']}
                    application={props.application}
                    disabled={props.disabled}
                    fileLimit={1}
                    fileType={DocumentTags.CERTIFICATE}
                    label={
                      <Flex direction={'column'} gap={1}>
                        <Text variant={'labelM'}>Document type</Text>
                        <Text variant={'bodyM'}>Certificate</Text>
                      </Flex>
                    }
                    name={`educationHistory.${index}.certificateDocuments`}
                    onRemove={(id: string) => {
                      const newEducationHistory = [...educationHistory]
                      newEducationHistory[index].certificateDocuments = newEducationHistory[
                        index
                      ].certificateDocuments.filter(file => file.id !== id)
                      setFieldValues({ educationHistory: newEducationHistory })
                    }}
                    section={educationHistory[index].id}
                    showHistory={!!props.disabled}
                    validate={value => {
                      const observableFiles = getObservableFiles({
                        fileType: DocumentTags.CERTIFICATE,
                        sectionReference: educationHistory[index].id,
                      })

                      const certificateHasValue =
                        !!value.length || !!Object.keys(observableFiles).length
                      const transcriptHasValue =
                        !!educationHistory[index].transcriptDocuments.length ||
                        !!Object.keys(
                          getObservableFiles({
                            fileType: DocumentTags.TRANSCRIPTS,
                            sectionReference: educationHistory[index].id,
                          }),
                        ).length

                      if (Object.keys(observableFiles).length > 1) {
                        return `This field has a file limit of ${1}.`
                      }

                      if (!(transcriptHasValue || certificateHasValue)) {
                        return 'At least one transcript or certificate must be attached.'
                      }
                      return true
                    }}
                  />
                </Flex>
              </Flex>
              {!props.disabled && Object.keys(educationHistory).length > 1 ? (
                <Flex>
                  <Button
                    emphasis="highlighted"
                    intent="negative"
                    width="fill"
                    leadIcon={DeleteOutlineIcon}
                    onClick={() => {
                      Object.keys(
                        getObservableFiles({
                          fileType: DocumentTags.TRANSCRIPTS,
                          sectionReference: educationHistory[index].id,
                        }),
                      ).forEach(id => {
                        addPendingDelete(id)
                      })
                      Object.keys(
                        getObservableFiles({
                          fileType: DocumentTags.CERTIFICATE,
                          sectionReference: educationHistory[index].id,
                        }),
                      ).forEach(id => {
                        addPendingDelete(id)
                      })
                      remove(index)
                    }}
                  >
                    {`Remove Education Experience ${index + 1}`}
                  </Button>
                </Flex>
              ) : null}
              <Divider />
            </Flex>
          )
        })}
      </Flex>
      {!props.disabled ? (
        <Flex>
          <Button
            emphasis="highlighted"
            intent="primary"
            width="fill"
            leadIcon={PlusOutlineIcon}
            onClick={() => {
              append(newEducationHistory())
            }}
          >
            Add another education experience
          </Button>
        </Flex>
      ) : null}
    </>
  )
}

function isFieldRequired(params: { disabled?: boolean }) {
  if (params.disabled) {
    return false
  }

  return true
}

function newEducationHistory(): EducationalAccomplishment {
  return {
    id: nanoid(),
    educationLevel: '',
    credentialReceived: '',
    country: '',
    startDate: '',
    endDate: '',
    transcriptDocuments: [],
    certificateDocuments: [],
  }
}

function setDefaultEducationHistory(
  educationItems?: SetAllFieldsOptional<EducationHistory>,
): EducationHistoryFormFields {
  const formObject: EducationHistoryFormFields = { educationHistory: [] }

  Object.entries(educationItems || []).forEach(([id, ehi]) => {
    formObject.educationHistory.push({
      id: id,
      educationLevel: ehi?.educationLevel || '',
      country: ehi?.educationCountry || '',
      credentialReceived: ehi?.credentialReceived || '',
      startDate: ehi?.startDate
        ? convertTimelessDateStrToLocalDate(ehi.startDate).toISOString()
        : '',
      endDate: ehi?.endDate ? convertTimelessDateStrToLocalDate(ehi.endDate).toISOString() : '',
      certificateDocuments: [],
      transcriptDocuments: [],
    })
  })

  if (isEmpty(formObject.educationHistory)) {
    formObject.educationHistory = [newEducationHistory()]
  } else {
    formObject.educationHistory.sort(
      (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime(),
    )
  }

  return formObject
}
