import { DatePicker, Flex, Text, TextInput, createForm } from '@applyboard/crystal-ui'
import { IAdditionalDocumentDialog, IPassportDocumentDialogForm } from '../types'
import { documentTypeOptions } from '../../../../utils'
import { DocumentTags } from '../../../../utils/enums'
import { FileUploadField } from '../../ApplicationForms/FileUploadField'
import { DocumentDialog } from '../DocumentDialog'
import { useUpdateRequestedDocuments } from '../../../../hooks'
import { useEffect, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { useApplicationFormContext } from '../../ApplicationForms/ApplicationForm'
import { Asterisk } from '../../../Asterisk'

type PassportRequestFormFields = {
  passportNumber: string
  passportExpiryDate: string
  passportFiles: Array<{
    id: string
    file: File
  }>
}

const { Form, Field, useFieldValues, useSetFieldValues } = createForm<PassportRequestFormFields>()

export function PassportDialog(props: IAdditionalDocumentDialog) {
  const { pendingFileUploadState, resetFiles } = useApplicationFormContext()
  const [open, setOpen] = useState(false)
  const { isUpdatingApplication, updateRequestedDocuments } = useUpdateRequestedDocuments({
    applicationId: props.application.id,
    index: props.index,
  })

  const passportId = useRef<string>()
  useEffect(() => {
    passportId.current = nanoid()
  }, [])

  const requestedDocuments = props.application.attributes?.requestedDocuments?.[props.id]
  if (!requestedDocuments) {
    return null
  }

  return (
    <DocumentDialog
      document={props.document}
      heading={`Add ${documentTypeOptions[props.document.documentType].label}`}
      loading={isUpdatingApplication}
      open={open}
      setOpen={open => {
        resetFiles({})
        setOpen(open)
      }}
      form={`passport-document-request-${passportId}`}
    >
      <Flex direction="column" gap={4}>
        <Text contrast="mid">{props.document.note}</Text>
        <Form
          id={`passport-document-request-${passportId}`}
          defaultValues={{
            passportNumber: '',
            passportExpiryDate: '',
            passportFiles: [],
          }}
          onSubmit={data => {
            updateRequestedDocuments({
              additionalData: {
                statusAndCitizenship: {
                  passports: {
                    [passportId.current as string]: {
                      passportNumber: data.passportNumber,
                      passportExpiry: data.passportExpiryDate?.substring(0, 10),
                    },
                  },
                },
              },
              dataFiles: pendingFileUploadState,
              documentType: DocumentTags.PASSPORT,
              onSuccess: () => {
                setOpen(false)
              },
              requestedDocuments,
              requestedDocumentsId: props.id,
              sectionReference: passportId.current,
            })
          }}
        >
          <PassportDialogForm
            passportId={passportId.current as string}
            application={props.application}
            document={props.document}
          />
        </Form>
      </Flex>
    </DocumentDialog>
  )
}

function PassportDialogForm(props: IPassportDocumentDialogForm) {
  const { getObservableFiles } = useApplicationFormContext()
  const { passportFiles } = useFieldValues(['passportFiles'])
  const setFieldValues = useSetFieldValues()
  const maxDate = new Date()
  const minDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 10)
  minDate.setFullYear(minDate.getFullYear() - 3)

  return (
    <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={TextInput}
          label="Passport number"
          name="passportNumber"
          required="Passport number is required"
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={DatePicker}
          label="Passport expiry date"
          name="passportExpiryDate"
          maxDate={maxDate.toISOString()}
          minDate={minDate.toISOString()}
          required="Passport expiry date is required"
        />
      </Flex.Item>
      <Flex gap={4} direction="column" basis="100%">
        <Field
          as={FileUploadField}
          allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
          application={props.application}
          fileLimit={1}
          fileType={DocumentTags.PASSPORT}
          label={
            <Text variant="bodyS" contrast="mid">
              Add the student's passport document below, supported file formats: JPG, JPEG, PDF, PNG, max
              number of files: 1 <Asterisk />
            </Text>
          }
          name={`passportFiles`}
          onRemove={(id: string) =>
            setFieldValues({ passportFiles: passportFiles.filter(file => file.id !== id) })
          }
          section={props.passportId}
          showHistory={false}
          validate={value => {
            const observableFiles = getObservableFiles({
              fileType: DocumentTags.PASSPORT,
              sectionReference: props.passportId,
            })

            if (Object.keys(observableFiles).length > 1) {
              return `This field has a file limit of ${1}.`
            }

            if (!(Object.keys(observableFiles).length || value.length)) {
              return 'This field is required'
            }
            return true
          }}
        />
      </Flex>
    </Flex>
  )
}
