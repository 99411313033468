import { AdditionalDocumentTags, DocumentTags } from './enums'

export const additionalDocumentTypeOptions: Record<AdditionalDocumentTags, { label: string }> = {
  [DocumentTags.STATEMENT_OF_PURPOSE]: { label: 'Statement of Purpose' },
  [DocumentTags.LETTER_OF_RECOMMENDATION]: { label: 'Letter of Recommendation' },
  [DocumentTags.CONSENT_FORM]: { label: 'Consent Form' },
  [DocumentTags.GMAT_SCORE]: { label: 'GMAT Score' },
  [DocumentTags.GRE_SCORE]: { label: 'GRE Score' },
  [DocumentTags.RESUME]: { label: 'Resume' },
  [DocumentTags.EMPLOYMENT_LETTER]: { label: 'Employment Letter' },
  [DocumentTags.VOLUNTEER_LETTER]: { label: 'Volunteer Letter' },
  [DocumentTags.ADDITIONAL_DOCUMENT]: { label: 'Other' },
}

export const documentTypeOptions: Record<DocumentTags, { label: string }> = {
  ...additionalDocumentTypeOptions,
  [DocumentTags.CERTIFICATE]: { label: 'Certificate' },
  [DocumentTags.DECLINED_LETTER]: { label: 'Declined Letter' },
  [DocumentTags.DESTINATION_COUNTRY_STATUS]: { label: 'Destination Country Status' },
  [DocumentTags.LANGUAGE_TEST]: { label: 'Language Proficiency' },
  [DocumentTags.LANGUAGE_TEST_IELTS]: { label: 'Language Proficiency' },
  [DocumentTags.LANGUAGE_TEST_PTE]: { label: 'Language Proficiency' },
  [DocumentTags.LANGUAGE_TEST_TOEFL]: { label: 'Language Proficiency' },
  [DocumentTags.LANGUAGE_TEST_OTHER]: { label: 'Language Proficiency' },
  [DocumentTags.PASSPORT]: { label: 'Passport' },
  [DocumentTags.PRE_OFFER_LETTER]: { label: 'Pre Offer Letter' },
  [DocumentTags.STUDY_PERMIT]: { label: 'Study Permit' },
  [DocumentTags.TRANSCRIPTS]: { label: 'Transcript' },
  [DocumentTags.VISITOR_TOURIST_VISA]: { label: 'Visitor Tourist Visa' },
  [DocumentTags.WORK_PERMIT]: { label: 'Work Permit' },
}
