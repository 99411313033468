import { RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'

import { ApiAgentRequest, request } from '../utils/request'
import { useAuthMutation } from './auth'
import { StudentDecisions } from 'applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

type UpdateDecisionLettersParams = {
  applicationId: string
  decision: StudentDecisions
}

type RawApplicationsResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

export function useUpdateDecisionLetters() {
  const { isPending, mutate, data } = useAuthMutation(
    async (params: UpdateDecisionLettersParams) => {
      return await request<RawApplicationsResponse>(
        new ApiAgentRequest(`/applications/${params.applicationId}/decisions`, {
          method: 'POST',
          body: {
            data: {
              id: params.applicationId,
              type: 'decisions',
              attributes: {
                decision: params.decision,
              },
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsResponse => res,
        },
      )
    },
  )

  return {
    isLoadingUpdateLettersDecision: isPending,
    updateLettersDecision: mutate,
    application: data?.data || null,
  }
}
