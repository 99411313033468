import { Intent } from '@applyboard/crystal-ui/lib/types/design'

import { ApplicationDetailsStatus } from '../types'

export function getTagInformation(status: ApplicationDetailsStatus): {
  label: string
  intent: Intent
} {
  switch (status) {
    case 'ACCEPTED':
      return {
        label: 'Accepted',
        intent: 'positive',
      }
    case 'DECLINED':
      return {
        label: 'Declined',
        intent: 'negative',
      }
    case 'REJECTED':
      return {
        label: 'Offer Rejected',
        intent: 'negative',
      }
    case 'PENDING':
    default:
      return {
        label: 'Pending',
        intent: 'warning',
      }
  }
}
