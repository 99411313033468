import { Flex, Select, Text, TextInput, createForm, useToasts } from '@applyboard/crystal-ui'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { DeferralOptions, RawApplicationAggregationResponse, useUpdateApplication } from '../../../../hooks'
import { nanoid } from 'nanoid'
import { useQueryClient } from '@tanstack/react-query'

type RequestDeferralFormFields = {
  programIntakeId: string
  reason: string
  otherReason: string
}

type RequestDeferralFormProps = {
  id: string
  onOpenChange: Dispatch<SetStateAction<boolean>>
  applicationId: string
  deferralOptions: DeferralOptions
  setIsUpdating: Dispatch<SetStateAction<boolean>>
}

const { Form, Field, useFieldValues } = createForm<RequestDeferralFormFields>()

export function RequestDeferralForm(props: RequestDeferralFormProps) {
  const toast = useToasts()
  const queryClient = useQueryClient()
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.applicationId,
  })

  useEffect(() => {
    props.setIsUpdating(isUpdatingApplication)
  }, [isUpdatingApplication, props])

  return (
    <Form
      id={props.id}
      defaultValues={{
        programIntakeId: '',
        reason: '',
        otherReason: '',
      }}
      onSubmit={data => {
        const deferralRequestId = nanoid()
        const reasonId = nanoid()
        const intake =
          props.deferralOptions.availableIntakes?.find(
            intake => intake.programIntakeId === data.programIntakeId,
          ) || null

        if (intake) {
          updateApplication(
            {
              attributes: {
                deferralRequests: {
                  [deferralRequestId]: {
                    programIntakeId: intake?.programIntakeId,
                    reason: {
                      [reasonId]: data.reason === 'Other' ? data.otherReason : data.reason,
                    },
                  },
                },
              },
            },
            {
              onSuccess: response => {
                queryClient.setQueryData(
                  ['applications', response.data.id],
                  (
                    oldData: RawApplicationAggregationResponse,
                  ): RawApplicationAggregationResponse => {
                    return {
                      data: {
                        ...oldData.data,
                        attributes: {
                          ...oldData.data.attributes,
                          application: response.data,
                          deferralOptions: null,
                        },
                      },
                    }
                  },
                )
                toast.primary('Your deferral request is being processed')
                props.onOpenChange(false)
              },
              onError: err => {
                if (err instanceof Error) {
                  toast.negative(new Error(err.message))
                }
              },
            },
          )
        } else {
          toast.negative(new Error('An error occurred'))
        }
      }}
    >
      <RequestDeferralFields deferralOptions={props.deferralOptions} />
    </Form>
  )
}

function RequestDeferralFields(props: Pick<RequestDeferralFormProps, 'deferralOptions'>) {
  const { reason } = useFieldValues(['reason'])

  return (
    <Flex direction="column" gap={8}>
      <Text>{props.deferralOptions.helperText}</Text>
      <Field
        as={Select}
        label="Choose your new intake"
        name="programIntakeId"
        appearance="styled"
        required="This field is required"
      >
        {props.deferralOptions.availableIntakes?.map(intake => (
          <Select.Option
            key={`intake-${intake.programIntakeId}`}
            value={intake.programIntakeId}
            label={intake.programIntakeTerm.name}
          />
        ))}
      </Field>
      <Field
        as={Select}
        label="Reason"
        name="reason"
        appearance="styled"
        required="This field is required"
      >
        {props.deferralOptions.reasonsForDeferral?.map((reasonForDeferral, index) => (
          <Select.Option key={index} value={reasonForDeferral} label={reasonForDeferral} />
        ))}
      </Field>
      {reason === 'Other' ? (
        <Field
          as={TextInput}
          label="Custom reason"
          name="otherReason"
          required="This field is required"
          helpText="Provide a custom reason with up to 200 characters."
          validate={val => {
            if (val.length > 200) {
              return 'Your custom reason should be no longer than 200 characters'
            }

            return true
          }}
        />
      ) : null}
    </Flex>
  )
}
