import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  createForm,
  Flex,
  Heading,
  Notice,
  TextInput,
} from '@applyboard/crystal-ui'

import { useAuthManager } from '../../hooks'
import { OnBoardingBox } from '../../components/OnBoardingBox'

const { Form, Field } = createForm<{ email: string; password: string }>()

export function LoginPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const { login, isLoggingIn } = useAuthManager()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  return (
    <OnBoardingBox>
      <Flex gap={10} direction="column">
        <Heading level={1} variant="headlineS" align="center">
          Log into your account
        </Heading>
        <Form
          defaultValues={{
            email: '',
            password: '',
          }}
          onSubmit={async ({ email, password }) => {
            setErrorMessage(undefined)
            login(
              { email, password },
              {
                onSuccess: () => {
                  if (location.state?.from) {
                    navigate(location.state?.from, { replace: true })
                  } else {
                    navigate('/', { replace: true })
                  }
                },
                onError: err => {
                  if (err instanceof Error) {
                    setErrorMessage(err?.message || 'The email or password you entered is invalid')
                  }
                },
              },
            )
          }}
        >
          <Flex gap={6} pb={4} direction="column">
            {errorMessage ? <Notice intent="negative">{errorMessage}</Notice> : null}
            <Field as={TextInput} name="email" label="Email" required="Email is required" />
            <Field
              as={TextInput}
              name="password"
              label="Password"
              required="Password is required"
              type="password"
            />
          </Flex>
          <Button intent="primary" loading={isLoggingIn} type="submit" width="fill">
            Log in
          </Button>
        </Form>
      </Flex>
    </OnBoardingBox>
  )
}
