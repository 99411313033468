import { Card, Divider, Flex } from '@applyboard/crystal-ui'
import { Intent } from '@applyboard/crystal-ui/lib/types/design'

import { ApplicationDetailsFile, ApplicationDetailsIntent, ApplicationDetailsStatus } from './types'
import { ApplicationDetailCardActions } from './ApplicationDetailCardActions'
import { ApplicationDetailCardDescription } from './ApplicationDetailCardDescription'
import { ApplicationDetailFile } from '../ApplicationDetailFile'
import { ApplicationDetailCardIssued } from './ApplicationDetailCardIssued'
import { ApplicationDetailCardTitle } from './ApplicationDetailCardTitle'
import { ApplicationDetailCardHelper } from './ApplicationDetailCardHelper'
import { ApplicationDetailCardRejectDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardRejectDialog'
import { ApplicationDetailCardAcceptDialogProps } from './ApplicationDetailCardActions/ApplicationDetailCardAcceptDialog'
import { StudentResponseEnum } from '../../../../utils'
import { DecisionResponseType } from 'applications-types-lib/dist/src/backend/applications-api/resources/applications/student-decisions'

type ApplicationDetailCardProps = Readonly<{
  title: string
  intent: ApplicationDetailsIntent
  issuedOn: Date
  file: ApplicationDetailsFile
  status?: DecisionResponseType
  statusChangedOn?: Date
  rejectDialog?: ApplicationDetailCardRejectDialogProps | null
  onOfferConditionsClick?: () => void
  helperTextIntent?: Intent
  helperText?: string
  acceptDialog?: ApplicationDetailCardAcceptDialogProps | null
  loading?: boolean
}>

export function ApplicationDetailCard(props: ApplicationDetailCardProps) {
  return (
    <Card aria-label="decision letter">
      <Flex direction="column">
        <Flex direction={{ xs: 'column', lg: 'row' }} gap={4}>
          <Flex gap={3} align="center" justify={{ xs: 'between', lg: 'start' }} grow={1} wrap>
            <ApplicationDetailCardTitle intent={props.intent} title={props.title} />
            <ApplicationDetailCardIssued title={props.title} intent={props.intent} issuedOn={props.issuedOn} />
          </Flex>

          <Flex.Item basis={{ xs: '100%', lg: '43%' }}>
            <ApplicationDetailFile url={props.file.url} name={props.file.name} />
          </Flex.Item>
        </Flex>

        <Divider />

        <Flex gap={{ lg: 4 }} direction={{ xs: 'column', lg: 'row' }} align={{ lg: 'center' }} justify={{ lg: props.status ? 'between' : 'end' }} pb={{ lg: props.status ? 0 : 4 }}>
          {props.status ? (
            <Flex.Item basis={{ lg: '53%' }}>
              <ApplicationDetailCardDescription
                status={props.status}
                statusChangedOn={props.statusChangedOn}
              />
            </Flex.Item>
          ) : null}
          {(props.acceptDialog && props.rejectDialog) || props.onOfferConditionsClick ? (
            <Flex direction={{ xs: 'column', lg: 'row' }} justify="end" gap={{ xs: 4, lg: 2 }} pb={{ xs: 4, lg: 0 }}>
              <ApplicationDetailCardActions
                rejectDialog={props.rejectDialog}
                onOfferConditionsClick={props.onOfferConditionsClick}
                loading={props.loading}
                acceptDialog={props.acceptDialog}
              />
            </Flex>
          ) : null}
        </Flex>

        {props.helperText ? (
          <Flex>
            <ApplicationDetailCardHelper
              text={props.helperText}
              intent={props.helperTextIntent as 'positive' | 'negative' | 'secondary'}
            />
          </Flex>
        ) : null}
      </Flex>
    </Card>
  )
}
