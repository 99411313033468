import { useState } from "react";
import { Button, ButtonLink, Card, DescriptionList, Divider, Flex, Tag, Text } from "@applyboard/crystal-ui"
import { ChevronDownOutlineIcon, ChevronUpOutlineIcon } from "@applyboard/ui-icons";

import { Application, ApplicationsPageProps, getApplicantName } from "../../../hooks"
import { formatDate } from "../../../utils";
import { Flag } from "../../../components/Flag";
import { usePageProps } from "../../../context";
import { ApplicationState } from "applications-types-lib";

type ApplicationsCardsProps = {
  readonly applications: Application[]
}

export function ApplicationCards({ applications }: ApplicationsCardsProps) {
  return (
    <Flex hideAbove="sm" direction="column" gap={4} pt={6}>
      {applications?.map((application) => (
        <ApplicationCard key={application.id} application={application} />
      ))}
    </Flex>
  )
}

type ApplicationCardProps = {
  readonly application: Application
}

function ApplicationCard({ application }: ApplicationCardProps) {
  const { referenceData } = usePageProps<ApplicationsPageProps>();
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false)

  const state = application.attributes.applicationState
  const tagProps = referenceData.applicationStatuses[state]

  return (
    <Card emphasis="outlined" as={'article'} aria-label="application-card">
      <Card.Header>
        <Flex justify={'between'}>
          <Flex direction={'column'}>
            <Text variant="bodyM">{getApplicantName(application)}</Text>
            <Text variant="labelS">{application.attributes.programSelected.program.name}</Text>
          </Flex>
          <Button
            aria-label={`View Additional Details for application ${application.attributes.humanReadableId}`}
            leadIcon={() => <ButtonIcon showAdditionalDetails={showAdditionalDetails} />}
            intent="secondary"
            emphasis={'outlined'}
            onClick={() => setShowAdditionalDetails(!showAdditionalDetails)}
          />
        </Flex>
        <DescriptionList>
          <DescriptionList.Item
            term={'App. ID'}
            details={`${application.attributes.humanReadableId}`}
          />
          <DescriptionList.Item
            term={'Intake'}
            details={`${application.attributes.programSelected.programIntakeTerm.name}`}
          />
          <DescriptionList.Item
            term={'Status'}
            details={<Tag icon="fill" size='sm' intent={tagProps.intent}>{tagProps.label}</Tag>}
          />
        </DescriptionList>
        {showAdditionalDetails ? (
          <>
            <Divider />
            <Flex direction={'column'}>
              <DescriptionList aria-label={`Additional Details for application ${application.attributes.humanReadableId}`}>
                <DescriptionList.Item
                  term={'Nationality'}
                  details={<Nationality application={application} />}
                />
                {!!application.attributes.applicationInformation?.submittedAt ?
                  (
                    <DescriptionList.Item
                      term={'Date submitted'}
                      details={formatDate(
                        new Date(application.attributes.applicationInformation?.submittedAt),
                        'en-US',
                        { month: '2-digit', day: "2-digit" }
                      )}
                    />
                  ) : null
                }
              </DescriptionList>
              {application.attributes?.applicationState === ApplicationState.DRAFT ? (
                <ButtonLink
                  href={`/applications/new?applicationId=${application.id}`}
                  intent="primary"
                  emphasis="highlighted"
                  width="fill"
                >
                  Finish Application
                </ButtonLink>
              ) : (
                <ButtonLink
                  aria-label={`Application ${application.attributes.humanReadableId}`}
                  href={`/applications/${application.id}`}
                  intent="primary"
                  emphasis="highlighted"
                  width="fill"
                >
                  View Application
                </ButtonLink>
              )}
            </Flex>
          </>
        ) : null
        }
      </Card.Header>
    </Card>
  )
}

type ButtonIconProps = {
  readonly showAdditionalDetails: boolean
}

function ButtonIcon(props: ButtonIconProps) {
  if (!props.showAdditionalDetails) return <ChevronDownOutlineIcon />
  return <ChevronUpOutlineIcon />
}

type NationalityProps = {
  readonly application: Application
}

function Nationality({ application }: NationalityProps) {
  const { referenceData } = usePageProps<ApplicationsPageProps>();

  const applicant = application.attributes.personalInformation?.basicPersonalInformation;
  const nationalities = referenceData.nationalities

  return (
    applicant?.nationality ? <Flex align="center" gap={2}>
      <Flag nationalityCode={applicant.nationality} />
      <Text>{nationalities[applicant.nationality]?.alpha3Code || ''}</Text>
    </Flex> : null
  )
}
